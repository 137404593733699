export let genNumPrefixes = [
  '',
  'mono',
  'di',
  'tri',
  'tetra',
  'penta',
  'hexa',
  'hepta',
  'octa',
  'nona',
  'deca',
  'undeca',
  'dodeca',
  'trideca',
  'tetradeca',
  'pentadeca',
  'hexadeca',
  'heptadeca',
  'octadeca',
  'nonadeca',
  'icosa',
  'henicosa',
  'docosa',
  'tricosa',
  'triaconta',
  'hentriaconta'
  ];