export let periodicLookup = {
  'Ac': { name: 'Actinium', mass: 227.0278 , atomic: 89, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Actinide' },
  'Ag': { name: 'Silver', mass: 107.8682 , atomic: 47, group: 11, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Al': { name: 'Aluminium', mass: 26.981539 , atomic: 13, group: 13, period: 3, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metal' },
  'Am': { name: 'Americium', mass: 243.0614 , atomic: 95, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Actinide' },
  'Ar': { name: 'Argon', mass: 39.948 , atomic: 18, group: 18, period: 3, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Noble gas' },
  'As': { name: 'Arsenic', mass: 74.92159 , atomic: 33, group: 15, period: 4, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metalloid' },
  'At': { name: 'Astatine', mass: 209.9871 , atomic: 85, group: 17, period: 6, block: 'p', state: 'Solid', occurance: 'Transient', desc: 'Halogen' },
  'Au': { name: 'Gold', mass: 196.96654 , atomic: 79, group: 11, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'B': { name: 'Boron', mass: 10.811 , atomic: 5, group: 13, period: 2, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metalloid' },
  'Ba': { name: 'Barium', mass: 137.327 , atomic: 56, group: 2, period: 6, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkaline earth metal' },
  'Be': { name: 'Beryllium', mass: 9.012182 , atomic: 4, group: 2, period: 2, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkaline earth metal' },
  'Bh': { name: 'Bohrium', mass: 262.1229 , atomic: 107, group: 7, period: 7, block: 'd', state: '', occurance: 'Synthetic', decs: 'Transition metal' },
  'Bi': { name: 'Bismuth', mass: 208.98037 , atomic: 83, group: 15, period: 6, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metal' },
  'Bk': { name: 'Berkelium', mass: 247.0703 , atomic: 97, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Actinide' },
  'Br': { name: 'Bromine', mass: 79.904 , atomic: 35, group: 17, period: 4, block: 'p', state: 'Liquid', occurance: 'Primordial', desc: 'Halogen' },
  'C': { name: 'Carbon', mass: 12.011 , atomic: 6, group: 14, period: 2, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Non-metal' },
  'Ca': { name: 'Calcium', mass: 40.078 , atomic: 20, group: 2, period: 4, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkaline earth metal' },
  'Cd': { name: 'Cadmium', mass: 112.411 , atomic: 48, group: 12, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Ce': { name: 'Cerium', mass: 140.115 , atomic: 58, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Cf': { name: 'Californium', mass: 251.0796 , atomic: 98, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Actinide' },
  'Cl': { name: 'Chlorine', mass: 35.4527 , atomic: 17, group: 17, period: 3, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Halogen' },
  'Cm': { name: 'Curium', mass: 247.0703 , atomic: 96, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Actinide' },
  'Cn': { name: 'Copernicium', mass: 277 , atomic: 112, group: 12, period: 7, block: 'd', state: '', occurance: 'Synthetic', desc: 'Transition metal' },
  'Co': { name: 'Cobalt', mass: 58.9332 , atomic: 27, group: 9, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Cr': { name: 'Chromium', mass: 51.9961 , atomic: 24, group: 6, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Cs': { name: 'Caesium', mass: 132.90543 , atomic: 55, group: 1, period: 6, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkali metal' },
  'Cu': { name: 'Copper', mass: 63.546 , atomic: 29, group: 11, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Db': { name: 'Dubnium', mass: 262.1138 , atomic: 105, group: 5, period: 7, block: 'd', state: '', occurance: 'Synthetic', desc: 'Transition metal' },
  'Ds': { name: 'Darmstadtium', mass: 269 , atomic: 110, group: 10, period: 7, block: 'd', state: '', occurance: 'Synthetic', desc: '' },
  'Dy': { name: 'Dysprosium', mass: 162.5 , atomic: 66, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Er': { name: 'Erbium', mass: 167.26 , atomic: 68, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Es': { name: 'Einsteinium', mass: 252.0829 , atomic: 99, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Synthetic', desc: 'Actinide' },
  'Eu': { name: 'Europium', mass: 151.965 , atomic: 63, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'F': { name: 'Fluorine', mass: 18.9984032 , atomic: 9, group: 17, period: 2, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Halogen' },
  'Fe': { name: 'Iron', mass: 55.847 , atomic: 26, group: 8, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Fl': { name: 'Flerovium', mass: 0 , atomic: 114, group: 14, period: 7, block: 'p', state: '', occurance: 'Synthetic', desc: '' },
  'Fm': { name: 'Fermium', mass: 257.0951 , atomic: 100, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Synthetic', desc: 'Actinide' },
  'Fr': { name: 'Francium', mass: 223.0197 , atomic: 87, group: 1, period: 7, block: 's', state: 'Solid', occurance: 'Transient', desc: 'Alkali metal' },
  'Ga': { name: 'Gallium', mass: 69.723 , atomic: 31, group: 13, period: 4, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metal' },
  'Gd': { name: 'Gadolinium', mass: 157.25 , atomic: 64, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Ge': { name: 'Germanium', mass: 72.61 , atomic: 32, group: 14, period: 4, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metalloid' },
  'H': { name: 'Hydrogen', mass: 1.00794 , atomic: 1, group: 1, period: 1, block: 's', state: 'Gas', occurance: 'Primordial', desc: 'Non-metal' },
  'He': { name: 'Helium', mass: 4.002602 , atomic: 2, group: 18, period: 1, block: 's', state: 'Gas', occurance: 'Primordial', desc: 'Noble gas' },
  'Hf': { name: 'Hafnium', mass: 178.49 , atomic: 72, group: 4, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Hg': { name: 'Mercury', mass: 200.59 , atomic: 80, group: 12, period: 6, block: 'd', state: 'Liquid', occurance: 'Primordial', desc: 'Transition metal' },
  'Ho': { name: 'Holmium', mass: 164.93032 , atomic: 67, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Hs': { name: 'Hassium', mass: 265 , atomic: 108, group: 8, period: 7, block: 'd', state: '', occurance: 'Synthetic', desc: 'Transition metal' },
  'I': { name: 'Iodine', mass: 126.90447 , atomic: 53, group: 17, period: 5, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Halogen' },
  'In': { name: 'Indium', mass: 114.82 , atomic: 49, group: 13, period: 5, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metal' },
  'Ir': { name: 'Iridium', mass: 192.22 , atomic: 77, group: 9, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'K': { name: 'Potassium', mass: 39.0983 , atomic: 19, group: 1, period: 4, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkali metal' },
  'Kr': { name: 'Krypton', mass: 83.8 , atomic: 36, group: 18, period: 4, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Noble gas' },
  'La': { name: 'Lanthanum', mass: 138.9055 , atomic: 57, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Li': { name: 'Lithium', mass: 6.941 , atomic: 3, group: 1, period: 2, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkali metal' },
  'Lr': { name: 'Lawrencium', mass: 260.1053 , atomic: 103, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Synthetic', desc: '' },
  'Lu': { name: 'Lutetium', mass: 174.967 , atomic: 71, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Lv': { name: 'Livermorium', mass: 0 , atomic: 116, group: 16, period: 7, block: 'p', state: '', occurance: 'Synthetic', desc: '' },
  'Mc': { name: 'Moscovium', mass: 0 , atomic: 115, group: 15, period: 7, block: 'p', state: '', occurance: 'Synthetic', desc: '' },
  'Md': { name: 'Mendelevium', mass: 258.0986 , atomic: 101, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Synthetic', desc: 'Actinide' },
  'Mg': { name: 'Magnesium', mass: 24.305 , atomic: 12, group: 2, period: 3, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkaline earth metal' },
  'Mn': { name: 'Manganese', mass: 54.93805 , atomic: 25, group: 7, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Mo': { name: 'Molybdenum', mass: 95.94 , atomic: 42, group: 6, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Mt': { name: 'Meitnerium', mass: 266 , atomic: 109, group: 9, period: 7, block: 'd', state: '', occurance: 'Synthetic', desc: '' },
  'N': { name: 'Nitrogen', mass: 14.00674 , atomic: 7, group: 15, period: 2, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Non-metal' },
  'Na': { name: 'Sodium', mass: 22.989768 , atomic: 11, group: 1, period: 3, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkali metal' },
  'Nb': { name: 'Niobium', mass: 92.90638 , atomic: 41, group: 5, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Nd': { name: 'Neodymium', mass: 144.24 , atomic: 60, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Ne': { name: 'Neon', mass: 20.1797 , atomic: 10, group: 18, period: 2, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Noble gas' },
  'Nh': { name: 'Nihonium', mass: 0 , atomic: 113, group: 13, period: 7, block: 'p', state: '', occurance: ' Synthetic', desc: '' },
  'Ni': { name: 'Nickel', mass: 58.69 , atomic: 28, group: 10, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'No': { name: 'Nobelium', mass: 259.1009 , atomic: 102, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Synthetic', desc: 'Actinide' },
  'Np': { name: 'Neptunium', mass: 237.0482 , atomic: 93, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Actinide' },
  'O': { name: 'Oxygen', mass: 15.9994 , atomic: 8, group: 16, period: 2, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Non-metal' },
  'Og': { name: 'Oganesson', mass: 0 , atomic: 118, group: 18, period: 7, block: 'p', state: '', occurance: 'Synthetic', desc: '' },
  'Os': { name: 'Osmium', mass: 190.2 , atomic: 76, group: 8, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'P': { name: 'Phosphorus', mass: 30.973762 , atomic: 15, group: 15, period: 3, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Non-metal' },
  'Pa': { name: 'Protactinium', mass: 231.0359 , atomic: 91, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Actinide' },
  'Pb': { name: 'Lead', mass: 207.2 , atomic: 82, group: 14, period: 6, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metal' },
  'Pd': { name: 'Palladium', mass: 106.42 , atomic: 46, group: 10, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Pm': { name: 'Promethium', mass: 146.9151 , atomic: 61, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Transient', desc: 'Lanthanide' },
  'Po': { name: 'Polonium', mass: 208.9824 , atomic: 84, group: 16, period: 6, block: 'p', state: 'Solid', occurance: 'Transient', desc: 'Metal' },
  'Pr': { name: 'Praseodymium', mass: 140.90765 , atomic: 59, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Pt': { name: 'Platinum', mass: 195.08 , atomic: 78, group: 10, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Pu': { name: 'Plutonium', mass: 244.0642 , atomic: 94, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Actinide' },
  'Ra': { name: 'Radium', mass: 226.0254 , atomic: 88, group: 2, period: 7, block: 's', state: 'Solid', occurance: 'Transient', desc: 'Alkaline earth metal' },
  'Rb': { name: 'Rubidium', mass: 85.4678 , atomic: 37, group: 1, period: 5, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkali metal' },
  'Re': { name: 'Rhenium', mass: 186.207 , atomic: 75, group: 7, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Rf': { name: 'Rutherfordium', mass: 261.1087 , atomic: 104, group: 4, period: 7, block: 'd', state: 'Solid', occurance: 'Synthetic', desc: '' },
  'Rg': { name: 'Roentgenium', mass: 272 , atomic: 111, group: 11, period: 7, block: 'd', state: '', occurance: 'Synthetic', desc: '' },
  'Rh': { name: 'Rhodium', mass: 102.9055 , atomic: 45, group: 9, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Rn': { name: 'Radon', mass: 222.0176 , atomic: 86, group: 18, period: 6, block: 'p', state: 'Gas', occurance: 'Transient', desc: 'Noble gas' },
  'Ru': { name: 'Ruthenium', mass: 101.07 , atomic: 44, group: 8, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'S': { name: 'Sulfur', mass: 32.066 , atomic: 16, group: 16, period: 3, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Non-metal' },
  'Sb': { name: 'Antimony', mass: 121.75 , atomic: 51, group: 15, period: 5, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metalloid' },
  'Sc': { name: 'Scandium', mass: 44.95591 , atomic: 21, group: 3, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Se': { name: 'Selenium', mass: 78.96 , atomic: 34, group: 16, period: 4, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Non-metal' },
  'Sg': { name: 'Seaborgium', mass: 263.1182 , atomic: 106, group: 6, period: 7, block: 'd', state: '', occurance: 'Synthetic', desc: 'Transition metal' },
  'Si': { name: 'Silicon', mass: 28.0855 , atomic: 14, group: 14, period: 3, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metalloid' },
  'Sm': { name: 'Samarium', mass: 150.36 , atomic: 62, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Sn': { name: 'Tin', mass: 118.71 , atomic: 50, group: 14, period: 5, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metal' },
  'Sr': { name: 'Strontium', mass: 87.62 , atomic: 38, group: 2, period: 5, block: 's', state: 'Solid', occurance: 'Primordial', desc: 'Alkaline earth metal' },
  'Ta': { name: 'Tantalum', mass: 180.9479 , atomic: 73, group: 5, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Tb': { name: 'Terbium', mass: 158.92534 , atomic: 65, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Tc': { name: 'Technetium', mass: 98.9063 , atomic: 43, group: 7, period: 5, block: 'd', state: 'Solid', occurance: 'Transient', desc: 'Transition metal' },
  'Te': { name: 'Tellurium', mass: 127.6 , atomic: 52, group: 16, period: 5, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metalloid' },
  'Th': { name: 'Thorium', mass: 232.0381 , atomic: 90, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Actinide' },
  'Ti': { name: 'Titanium', mass: 47.88 , atomic: 22, group: 4, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Tl': { name: 'Thallium', mass: 204.3833 , atomic: 81, group: 13, period: 6, block: 'p', state: 'Solid', occurance: 'Primordial', desc: 'Metal' },
  'Tm': { name: 'Thulium', mass: 168.93421 , atomic: 69, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Ts': { name: 'Tennessine', mass: 0 , atomic: 117, group: 17, period: 7, block: 'p', state: '', occurance: 'Synthetic', desc: '' },
  'U': { name: 'Uranium', mass: 238.0289 , atomic: 92, group: 3, period: 7, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Actinide' },
  'Ubb': { name: 'Unbibium', mass: 0 , atomic: 122, group: 4, period: 8, block: '', state: '', occurance: '', desc: '' },
  'Ubh': { name: 'Unbihexium', mass: 0 , atomic: 126, group: 8, period: 8, block: 'g', state: '', occurance: 'Synthetic', desc: '' },
  'Ubn': { name: 'Unbinilium', mass: 0 , atomic: 120, group: 2, period: 8, block: 's', state: '', occurance: 'Synthetic', desc: '' },
  'Ubp': { name: 'Unbipentium', mass: 0 , atomic: 125, group: 7, period: 8, block: '', state: '', occurance: '', desc: '' },
  'Ubq': { name: 'Unbiquadium', mass: 0 , atomic: 124, group: 6, period: 8, block: '', state: '', occurance: '', desc: '' },
  'Ubt': { name: 'Unbitrium', mass: 0 , atomic: 123, group: 5, period: 8, block: '', state: '', occurance: '', desc: '' },
  'Ubu': { name: 'Unbiunium', mass: 0 , atomic: 121, group: 3, period: 8, block: '', state: '', occurance: '', desc: '' },
  'Uue': { name: 'Ununnenium', mass: 0 , atomic: 119, group: 1, period: 8, block: 's', state: '', occurance: 'Synthetic', desc: '' },
  'V': { name: 'Vanadium', mass: 50.9415 , atomic: 23, group: 5, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'W': { name: 'Tungsten', mass: 183.85 , atomic: 74, group: 6, period: 6, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Xe': { name: 'Xenon', mass: 131.29 , atomic: 54, group: 18, period: 5, block: 'p', state: 'Gas', occurance: 'Primordial', desc: 'Noble gas' },
  'Y': { name: 'Yttrium', mass: 88.90585 , atomic: 39, group: 3, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Yb': { name: 'Ytterbium', mass: 173.04 , atomic: 70, group: 3, period: 6, block: 'f', state: 'Solid', occurance: 'Primordial', desc: 'Lanthanide' },
  'Zn': { name: 'Zinc', mass: 65.39 , atomic: 30, group: 12, period: 4, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
  'Zr': { name: 'Zirconium', mass: 91.224 , atomic: 40, group: 4, period: 5, block: 'd', state: 'Solid', occurance: 'Primordial', desc: 'Transition metal' },
};

export function getElementByName(s) {
  return Object.values(periodicLookup).find(({ name }) => name.toLowerCase() === s.toLowerCase());
}

export function getSymbolFromElement(e) {
  return Object.keys(periodicLookup)[Object.values(periodicLookup).indexOf(e)]
}

export function getElementSymbolByName(s) {
  return getSymbolFromElement(getElementByName(s));
}